import React, { Component } from 'react';
import '../App.css';
import greeneth from '../assets/green-eth.png';
import compass from '../assets/compass.png';
import ipfs from '../assets/ipfs.png';
import key from '../assets/key.png';
import mirror from '../assets/mirror.png';

class Story4 extends Component {

    render() {
        return (

            <div class="boxWrap3-2-2-2">

                <div class="imgT">BUILT RIGHT FROM THE BEGINNING</div>

                <div class="imgMain">

                    <div class="imges2">
                        <div class="imgAndCon">
                            <img src={mirror} />
                            <div>You don't own the IP</div>

                        </div>

                        <div class="imgAndCon">
                            <img src={greeneth} />
                            <div>Minted on ETH</div>

                        </div>
                    </div>

                    <div class="imges2-2">
                        <div class="imgAndCon">
                            <img src={ipfs} />
                            <div >IPFS-hosted</div>
                        </div>

                        <div class="imgAndCon">
                            <img src={compass} />
                            <div>Community based NFT</div>

                        </div>
                    </div>
                    <div class="imgAndCon2M">
                        <img src={key} />
                        <div>Access to ?</div>
                    </div>

                    <div class="imgMain2">
                        <div class="imgAndCon">
                            <img src={mirror} />
                            <div>You don't own the IP</div>

                        </div>

                        <div class="imgAndCon">
                            <img src={greeneth} />
                            <div>Minted on ETH</div>

                        </div>

                        <div class="imgAndCon">
                            <img src={ipfs} />
                            <div >IPFS-hosted</div>
                        </div>

                        <div class="imgAndCon">
                            <img src={compass} />
                            <div>Community based NFT</div>

                        </div>

                        <div class="imgAndCon2">
                            <img src={key} />
                            <div>Access to ?</div>
                        </div>
                    </div>
                </div>
            </div>


        );

    }
}

export default Story4;