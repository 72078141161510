import React, { Component } from 'react';
import '../App.css';
import door from '../assets/door.png';

class Story1 extends Component {


    render() {
        return (

            <div class="boxWrap3-1">

                <div class="UtilDivM">
                    <img class="storyPic2M" src={door} />
                </div>

                <div class="uR">
                    <div class="storyH1">A collection built <br /> with community <br /> at the foundation</div>
                    <div class="storyH1M">A collection built with community at the foundation</div>
                    <p class="storyCon2">At Alienbirds, we like building with community in mind. That’s why no gated discord will ever be created and we welcome everyone in our twitter to join us and interact with the Alienbirds community.</p>
                </div>

                <div class="UtilDiv">
                    <img class="storyPic2" src={door} />
                </div>


            </div>


        );

    }
}

export default Story1;