import React, { Component } from 'react';
import '../App.css';

class Story2 extends Component {


    render() {
        return (

            <div class="boxWrap3-2">

                <div class="cTMain">
               <p class="cT">Community + more</p>
               <p class="cT2">Coming soon...</p>
               </div>
            </div>


        );

    }
}

export default Story2;