import React, { Component } from 'react';
import '../App.css';

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 15;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);




class FAQ extends Component {


  render() {
    return (

      <div id="faq" class="faqbg">



        <div class="accordin reveal">
        <div class="faqTitle reveal">Frequently Asked <br/> Questions</div>

          <details>
            <summary >Is there a Discord?</summary>
            <div class="faq__content ">
              <p data-aos="zoom-in">No.</p>
            </div>
          </details >
          
          <details >
            <summary>Is there a guaranteed mint allowlist?</summary>
            <div class="faq__content">
              <p data-aos="zoom-in">No.</p>

            </div>
          </details>
          <details >
            <summary>What if some winners on the guaranteed mint allowlist do not mint?</summary>
            <div class="faq__content">
              <p data-aos="zoom-in">There is no guaranteed mint allowlist.</p>
            </div>
          </details>

          <details >
            <summary>What are the maximum mints per wallet?</summary>
            <div class="faq__content">
              <p data-aos="zoom-in">3</p>

            </div>
          </details>

          <details >
            <summary>What time can guaranteed mint allowlist winners mint?</summary>
            <div class="faq__content">
              <p data-aos="zoom-in">There is no guaranteed mint allowlist.</p>

            </div>
          </details>

          <details reveal>
            <summary>How are the 10,000 Alienbirds distributed?</summary>
            <div class="faq__content">
              <p data-aos="zoom-in">All public sale.</p>

            </div>
          </details>

          <details reveal>
            <summary>What are the funds being used for?</summary>
            <div class="faq__content">
              <p data-aos="zoom-in">You’ll see.</p>

            </div>
          </details>

          <details reveal>
            <summary>Is there a future roadmap?</summary>
            <div class="faq__content">
              <p data-aos="zoom-in">Maybe.</p>

            </div>
          </details>

          <details reveal>
            <summary>I’m a PROOF Collective Member. Will my Alienbird be different?</summary>
            <div class="faq__content">
              <p data-aos="zoom-in">No.</p>

            </div>
          </details>

          <details reveal>
            <summary>What are the rewards for nesting?</summary>
            <div class="faq__content">
              <p data-aos="zoom-in">Nesting is not in our plans. For now.</p>

            </div>
          </details>

          <details reveal>
            <summary>How soon after mint will nesting be available?</summary>
            <div class="faq__content">
              <p data-aos="zoom-in">Nesting is not in our plans. For now.</p>

            </div>
          </details>

          <details reveal>
            <summary>Will nesting lead to a decrease in “holder number”?</summary>
            <div class="faq__content">
              <p data-aos="zoom-in">Nesting is not in our plans. For now.</p>

            </div>
          </details>

          <details reveal>
            <summary>Are there other benefits to nesting my Alienbirds?</summary>
            <div class="faq__content">
              <p data-aos="zoom-in">Nesting is not in our plans. For now.</p>

            </div>
          </details>

          <details reveal>
            <summary>Can I sell or list my Alienbird while it’s nesting?</summary>
            <div class="faq__content">
              <p data-aos="zoom-in">Nesting is not in our plans. For now.</p>

            </div>
          </details>

          <details reveal>
            <summary>Can I transfer my Alienbird without un-nesting it?</summary>
            <div class="faq__content">
              <p data-aos="zoom-in">Nesting is not in our plans. For now.</p>

            </div>
          </details>

          <details reveal>
            <summary>What are secondary royalties and how will they be used?</summary>
            <div class="faq__content">
              <p data-aos="zoom-in">10%. You’ll see.</p>

            </div>
          </details>

          <details reveal>
            <summary>Will Alienbirds be revealed right away?</summary>
            <div class="faq__content">
              <p data-aos="zoom-in">Yes.</p>

            </div>
          </details>


        </div>
      </div>


    )
  }
}

export default FAQ;

