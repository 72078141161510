import React, { Component } from 'react';
import '../App.css';
import NFTs from '../assets/birds.png';

class Story extends Component {


    render() {
        return (

            <div class="boxWrap3">

                <div class="UtilDiv1">
                    <img class="storyPic1" src={NFTs} />
                </div>

                <div class="uR">
                    <div class="storyH">Alienbirds are <br/> more than just <br/> an avatar</div>
                    <div class="storyHM">Alienbirds are more than just an avatar</div>

                    <p class="storyCon">They are a collection of 10,000 alien PFPs that feature a richly diverse and unique pool of rarity-powered traits.</p>
                    
                </div>


            </div>


        );

    }
}

export default Story;