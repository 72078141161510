import React, { Component } from 'react';
import twitter from '../assets/twitter.png';
import globe from '../assets/globe.png';


const tLink = () => {
	window.open("https://twitter.com/alienbirds_");
}

class Footer extends Component {


	render() {

		return (

			<div class="footer">

				<div class="fMain">
				<div class="fLine"></div>
				<img class="globe" src={globe}/>
				<div class="fLine"></div>
				</div>

				<div class="footer2">
				<div class="f1">
					<div class="copyright">ALIENBIRDS</div>
				</div>

				<div class="f2">
					<img class="twitterF" onClick={tLink} src={twitter}/>
				</div>
				</div>
			</div>)
	}
}

export default Footer;